.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

::-webkit-scrollbar {
  display: none;
}

/* body{
  overflow: hidden;
} */

.div-loading{
  width: 100px;
  margin: 0 auto;
  margin-top: 20%;
}

.width-total{
  width: 100%;
}

.header-block{
  position: fixed;
  top: 0px;
  background-color: #701ff3;
  text-align: right;
  padding: 20px 90px;
  height: 68px;
  width: 100%;
  z-index: 2;
  box-shadow: 5px 3px 20px #888888;
}

.body-block{
  background-color: #fff;
  min-height: 90vh;
  padding-top: 120px;
}

.footer-block{
  background-color: #E6E6E6;
  padding: 20px;
  height: 64px;
  text-align: center;
}

.footer-block-static{
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: -1;
  background-color: #E6E6E6;
  padding: 20px;
}

/*GUEST*/
.login-div{
  width: 50%;
  margin: 0 auto;
  padding-top: 30px;
}

.header-login{
  text-align: center;
  margin-top: 10px;
  margin-bottom: 50px;
}

.footer-login{
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.div-element{
  text-align: center;
  margin-bottom: 30px;
}

.input{
  width: 45%;
}

.button{
  width: 30%; 
  height: 50px; 
  background-color: '#3a4f9e';
}

.button-register{
  width: 150px; 
  height: 50px; 
  background-color: #3a4f9e;
  margin-top: 10px;
  margin-bottom: 40px;
  margin-right: 30px;
}

.btn-close{
  position: absolute;
  top: 0px;
  right: 10px;
  z-index: 2;
  cursor: pointer;
  color: #3f3f3f;
  text-decoration: none;
}

.btn-close:hover{
  color: #3a4f9e;
  text-decoration: none;
}

.register-div{
  margin: 0 auto;
}


.evaluation-div{
  width: 90%;
  margin: 0 auto;
  height: 100%;
}

/**EVALUATION**/
.card1 {
  padding: 60px 0px 0px 70px;
  background-color: white;
  margin-bottom: 35px;
  /* text-align: justify; */
  /* font-size: 1.2em; */
}

.buttom-right{
  float: right;
}

.buttom-left{
  float: left;
}

.div-cam-hide{
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: white;
  z-index: 10;
}

.div-msg-device{
  padding: 10 px;
  font-size: 0.7em;
  color: red;
}

.text-save-result{
  width: 50%;
  margin: 0 auto;
  font-size: 1em; 
  color: red; 
  text-align: center;
  margin-bottom: 30px;
}

.text-end{
  width: 50%;
  margin: 0 auto;
  font-size: 1em; 
  text-align: center;
  margin-bottom: 30px;
}

.div-cam{
  position: absolute;
  /* top: -230px;
  left: -230px;
  z-index: -10; */
  bottom: 10px;
  left: 10px;
  z-index: -10;
}

.loader-inactive{
  height: 0%;
  padding: 20px;
  animation-name: closediv;
  animation-duration: 10s;
  margin: 0 auto;
  width: 150px;
}

.loader-active{
  height: 100%;
  padding: 20px;
  animation-name: opendiv;
  animation-duration: 10s;
  margin: 0 auto;
  width: 150px;
}

@keyframes opendiv {
  from {height: 0%;visibility: hidden;}
  to {height: 100%;visibility: visible;}
}

@keyframes closediv {
  from {width: 100%;visibility: visible;}
  to {width: 0%;visibility: hidden;}
}

/*********************************/
.div-logo-company{
  background-color: #fff;
  width: 200px;
  height: 150px;
  position: fixed;
  top: 0px;
  left: 105px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 1px 7px  10px #f0f0f0;
  text-align: center;
  padding-top: 35px;
  z-index: 3;
}

.separate-grid{
  border-left: solid 1px #D8D8D8;
}

.button-next{
  margin-top: 20px;
}

.items-sequence{
  margin-top: 150px;
  
  font-weight: 500;
}

.list-sequence{
  list-style-type: none;
  padding-left: 0px;
}

ul.list-sequence li {
  padding-bottom: 2ex;
}

.text-color-active{
  color: #000;
  font-size: 1.2em;
}

.text-color-inactive{
  color: rgb(199 192 192);
  font-size: 1.1em;
}

.list-intructions{
  margin-top: 20px;
}

ol.list-intructions li {
  padding-bottom: 1ex;
}
.div-question {
  padding: 0px 70px 0px 0px;
  margin-top: 134px;
  background-color: white;
  font-size: 1.5em;
  font-weight: 400;
}

.div-aswers {
  padding: 0px 0px 0px 70px;
  margin-top: 145px;
  background-color: white;
  font-size: 1.2em;
}

.button-answer{
  justify-content: flex-start !important;
  background-color: #f9f9f9 !important;
  margin-bottom: 20px !important;
  width: 100%;
  font-size: 1em !important;
  padding-left: 20px !important;
  border-radius: 15px !important;
}

.button-answer-normal{
  background-color: #f9f9f9 !important; 
}

.button-answer-select{
  background-color: #d4d4d4 !important; 
}

.buttons-argument{
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: right;
}

.argument-answer{
  resize: none;
  width: 100%;
  background-color: #f9f9f9;
  border: none;
  border-radius: 15px;
  padding: 10px;
}

.button-argument1{
  background-color: #0ac457 !important;
  margin-right: 20px !important;
  color: #fff !important;
}

Button{
  text-transform: none !important;
}

.div-empty{
  background-color: #f9f9f9;
}

.div-final {
  padding: 0px 70px 0px 0px;
  margin-top: 134px;
  background-color: white;
  font-size: 1.5em;
  font-weight: 400;
  margin-left: 68px;
}

.section-paragraph{
  font-size: 1.1em;
  text-align: justify;
}

.check-consent{
  font-size: 1.1em !important;
  font-weight: normal !important;
  letter-spacing: normal !important;
}
/**************/
/*****EVALUATION IQ ********/
.evaluation-header{
  font-size: 25px;
  padding: 15px 90px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.border-question-lg{
  border-right: solid 1px #A4A4A4;
  padding: 20px; 
  font-weight: bold;
  text-align: center;
}

.border-question-xs{
  border-bottom: solid 1px #A4A4A4;
  padding: 20px; 
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.text-clock{
  color: #3a4f9e; 
  font-size: 30px;
  font-weight: 500;
}

.body-evaluation-iq{
  height: 390px;
  margin-top: 20px;
  padding: 10px 90px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.disabled-mask-active{
  width: 100%;
  height: 100%; 
  background: rgba(255, 255, 255, 0.6);
  z-index: 2;
  position: absolute;
  top: 0px;
}

.disabled-mask-inactive{
  width: 0px;
  height: 0px;
}


/**************/

@media screen and (min-width: 481px) and (max-width: 959px) {
  .input{
    width: 70%;
  }

  .button{
    width: 40%;
  }

  .text-end{
    width: 80%;
  }
}

@media screen and (max-width: 480px) {
  .login-div{
    width: 100%;
  }
  
  .input{
    width: 100%;
  }

  .button{
    width: 80%;
  }
  .text-end{
    width: 100%;
  }

  .card1 {
    padding: 20px 5px 20px 5px;
  }

  .body-block{
    width: auto;
  }

  .evaluation-header{
    font-size: 17px;
    padding: 15px 20px;
  }
  .text-clock{
    font-size: 25px;
  }

  .footer-block{
    height: 80px;
  }

  .login-div {
    width: 90%;
  }

  .separate-grid{
    border-left: none;
  }

  .div-question{
    padding: initial;
  }

  .div-aswers{
    padding: initial;
    margin-top: 50px;
  }
}